import Vue from "vue";
import VueRouter from "vue-router"
//2.使用路由
Vue.use(VueRouter);
//3.创建VueRouter的实例
const router=new VueRouter({
    //4.配置路由的path和组件
    routes:[
        //配置路由的路径
        // 路由默认跳转
        {
            path: '/', // 如果路由为/
            redirect: '/homeUs' //重定向到首页组件
        },
        {
            path: "/homeCn",
            name:'homeCn',
            component: () => import("../views/homeCn.vue"),
        },
        {
            path: "/homeTw",
            name:'homeTw',
            component: () => import("../views/homeTw.vue"),
        },
        {
            path: "/homeUs",
            name:'homeUs',
            component: () => import("../views/homeUs.vue"),
        },
        {
            path: "/partnerCn",
            name:'partnerCn',
            component: () => import("../views/partnerCn.vue"),
        },
        {
            path: "/partnerTw",
            name:'partnerTw',
            component: () => import("../views/partnerTw.vue"),
        },
        {
            path: "/partnerUs",
            name:'partnerUs',
            component: () => import("../views/partnerUs.vue"),
        },
        {
            path: "/aboutCn",
            name:'aboutCn',
            component: () => import("../views/aboutCn.vue"),
        },
        {
            path: "/aboutTw",
            name:'aboutTw',
            component: () => import("../views/aboutTw.vue"),
        },
        {
            path: "/aboutUs",
            name:'aboutUs',
            component: () => import("../views/aboutUs.vue"),
        },
        {
            path: "/contactCn",
            name:'contactCn',
            component: () => import("../views/contactCn.vue"),
        },
        {
            path: "/contactTw",
            name:'contactTw',
            component: () => import("../views/contactTw.vue"),
        },
        {
            path: "/contactUs",
            name:'contactUs',
            component: () => import("../views/contactUs.vue"),
        },
        {
            path: "/privacyCn",
            name:'privacyCn',
            component: () => import("../views/privacyCn.vue"),
        },
        {
            path: "/privacyTw",
            name:'privacyTw',
            component: () => import("../views/privacyTw.vue"),
        },
        {
            path: "/privacyUs",
            name:'privacyUs',
            component: () => import("../views/privacyUs.vue"),
        },
    ]
})
// router.beforeEach((to,from,next) => {
//     console.log(to.path,from,next)
//     if(to.path === '/'){
//       next('/home')
//     }
// })
//5.导入路由实例
export default router